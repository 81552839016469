import * as React from "react";
import { SVGProps } from "react";

const FileZipIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      {...props}
      className={`min-icon`}
      fill={"currentcolor"}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="-19.281 0 256 256"
    >
      <g transform="translate(3467.334 -3812.007)">
        <path
          data-name="Trazado 436"
          d="M-3395.774 3897.42a17.686 17.686 0 00-5.245-10.691c-2.691-2.615-7.129-5.479-11.512-5.676-10-.465-18.68 6.74-19.754 16.366l-2.853 25.542a21.725 21.725 0 00-.126 2.327 21.263 21.263 0 0021.193 21.28h.043a20.635 20.635 0 0018.294-11.19 27.973 27.973 0 002.932-12.257c.2-5.784-.8-11.752-1.756-17.522-.475-2.872-.926-5.585-1.216-8.179zm-18.24-3.68a4.229 4.229 0 014.224 4.224 4.23 4.23 0 01-4.224 4.225 4.231 4.231 0 01-4.225-4.225 4.23 4.23 0 014.226-4.224zm0 40.129a10.573 10.573 0 01-10.561-10.561 10.573 10.573 0 0110.561-10.56 10.573 10.573 0 0110.561 10.56 10.574 10.574 0 01-10.56 10.561z"
        />
        <circle
          data-name="Elipse 63"
          cx={2.111}
          cy={2.111}
          r={2.111}
          transform="translate(-3416.125 3921.197)"
        />
        <path
          data-name="Trazado 437"
          d="M-3250.337 3889.2c.387-15.754.958-32.242-.5-47.942-1.093-11.755-6.192-21.735-17.832-25.915a42.461 42.461 0 00-5.016-1.447c-6.549-1.479-13.432-1.6-20.09-1.8-11.727-.344-23.449.364-35.176.314-23.494-.1-46.986.322-70.479.414q-4.228.016-8.456.017h-42.206a17.26 17.26 0 00-17.241 17.24v220.68a17.26 17.26 0 0017.241 17.24h182.54a17.26 17.26 0 0017.241-17.24v-109.942c-.001-17.184-.448-34.459-.026-51.619zm-20.922 145.987a14.889 14.889 0 01-14.888 14.888h-118.62v-21.342c.206.015.412.032.621.032h16.262a8.456 8.456 0 008.455-8.455 8.455 8.455 0 00-8.455-8.455h-16.262c-.209 0-.415.016-.621.031v-23.6c.206.015.412.032.621.032h16.262a8.455 8.455 0 008.455-8.455 8.455 8.455 0 00-8.455-8.455h-16.262c-.209 0-.415.017-.621.031v-20.75a20.4 20.4 0 01-9.259 2.222h-.043a21.066 21.066 0 01-7.607-1.45l-.567-.029h-16.257a8.454 8.454 0 00-8.455 8.455 8.454 8.454 0 008.455 8.455h16.261c.192 0 .379-.016.567-.028v23.186a8.726 8.726 0 00-.8-.04h-16.261a8.455 8.455 0 00-8.455 8.455 8.455 8.455 0 008.455 8.455h16.261c.269 0 .535-.016.8-.041v41.751h-12.839a14.89 14.89 0 01-14.889-14.888v-189.527a14.889 14.889 0 0114.889-14.888h99.548v25.458a37.8 37.8 0 0037.757 37.76h25.952zm0-157.657h-25.952a21.319 21.319 0 01-21.295-21.3v-25.458h32.359a14.888 14.888 0 0114.888 14.888z"
        />
      </g>
    </svg>
  );
};

export default FileZipIcon;
